import {
  Sidebar,
  SidebarHeader,
  Button,
  SidebarContent,
  SidebarFooter,
  Badge,
  Typography
} from '@org-crowley/enterprise-react-component-library';
import { useContext } from 'react';
import { useFilterState } from '../../utils/useFilterState';
import { FilterForm } from './FilterForm';
import { FilterContext } from '../../contexts/FilterContext';
import cn from 'classnames';
import { MdClose } from 'react-icons/md';

interface Props {
  onFilterSidebarClose: () => void;
}

export const FilterSidebar = ({ onFilterSidebarClose }: Props) => {
  const { activeToggleFilters, applyMultipleToggleFilters } =
    useContext(FilterContext);

  const {
    activeToggleFilters: selectedToggleFilters,
    onFilterChangeSingleValue,
    resetAllFilters: resetSelectedFilters
  } = useFilterState({
    initialToggleFilterState: activeToggleFilters,
    persist: false
  });

  const onApplyClick = () => {
    applyMultipleToggleFilters(selectedToggleFilters);
    onFilterSidebarClose();
  };

  const onClearClick = () => {
    resetSelectedFilters();
  };

  return (
    <Sidebar>
      <SidebarHeader className="flex justify-between items-center">
        <div className=" flex items-center">
          <Typography variant="h600" as="div" className={cn('ml-3')}>
            <span className="mr-4" data-testid="filter-sidebar-header">
              Filters
            </span>
          </Typography>
          <Badge
            variant="primary"
            size="large"
            showBackgroundColor={Object.keys(selectedToggleFilters).length > 0}
          >
            {Object.keys(selectedToggleFilters).length}
          </Badge>
        </div>
        <MdClose
          size={24}
          role="button"
          tabIndex={0}
          aria-label="Cancel"
          onClick={onFilterSidebarClose}
          className="outline-none"
        />
      </SidebarHeader>
      <SidebarContent>
        <FilterForm
          toggleFilters={selectedToggleFilters}
          onCheckboxChange={onFilterChangeSingleValue}
        />
      </SidebarContent>
      <SidebarFooter className="justify-between gap-5">
        <Button
          variant="tertiary"
          className="w-full"
          onClick={onClearClick}
          disabled={!Object.keys(selectedToggleFilters).length}
          data-testid="filter-sidebar-clear-button"
        >
          Clear All
        </Button>
        <Button
          className="w-full"
          variant="primary"
          onClick={onApplyClick}
          data-testid="filter-sidebar-apply-button"
        >
          Apply
        </Button>
      </SidebarFooter>
    </Sidebar>
  );
};
