import { isNil } from 'lodash';
import { Vessel } from '../models/Vessel';
import { BooleanFilterBy, TextFilterBy } from './useFilterState';

/**
 *
 * @param vessels Array of vessels
 * @param attr key in the vessels object
 * @returns Object with vessels grouped by attr and their count
 */
export function groupVesselsByAttr(
  vessels: Vessel[],
  attr: TextFilterBy
): Record<string, number> {
  return vessels.reduce((acc, curr) => {
    const key = curr[attr];
    if (key && acc[key]) {
      return {
        ...acc,
        [key]: acc[key] + 1
      };
    } else if (key) {
      return {
        ...acc,
        [key]: 1
      };
    }

    return acc;
  }, {} as Record<string, number>);
}

export function groupVesselsByBooleanAttr(
  vessels: Vessel[],
  attr: BooleanFilterBy,
  isUndefinedTruthy: boolean
): Record<string, number> {
  const hasTrueItems = !!vessels.filter((vessel) => vessel[attr]).length;
  const hasFalseItems = !!vessels.filter((vessel) => vessel[attr] === false)
    .length;
  const hasUndefinedItems = !!vessels.filter((vessel) => isNil(vessel[attr]))
    .length;

  return {
    ...(hasTrueItems && { true: 1 }),
    ...(hasFalseItems && { false: 1 }),
    ...(hasUndefinedItems && (isUndefinedTruthy ? { true: 1 } : { false: 1 }))
  };
}

export function groupVesselsByAttrWithEnums(
  attrEnum: { [key: string]: string },
  customLabel?: (input: string | undefined) => string | undefined
): { options: Record<string, number>; optionLabels: Record<string, string> } {
  let options = {};
  let labels = {};
  Object.keys(attrEnum).map((enumKey) => {
    const key = attrEnum[enumKey];
    const label = customLabel ? customLabel(key) : key;
    options = { ...options, [key]: 0 };
    labels = { ...labels, [key]: label };
  });

  return { options, optionLabels: labels };
}

export const customCycloneLabel = (input: string | undefined) => {
  if (input === 'N') return 'No';
  if (input === 'Y') return 'Yes';
  return undefined;
};

export const groupTeamMemberNames = (
  vessels: Vessel[]
): Record<string, number> => {
  const nameCount: Record<string, number> = {};

  vessels.forEach((vessel) => {
    if (vessel.Team) {
      vessel.Team.TeamMembers.forEach((member) => {
        const name = member.Name;
        nameCount[name] = (nameCount[name] || 0) + 1;
      });
    }
  });

  return nameCount;
};
