import {
  Checkbox,
  Typography
} from '@org-crowley/enterprise-react-component-library';
import cn from 'classnames';
import { translateFleetName } from './FilterForm';
import { FilterBy } from '../../utils/useFilterState';

interface FilterOptionsProps {
  options: string[];
  optionLabels: Record<string, string> | undefined;
  filterType: FilterBy;
  activeFilters: Set<string> | undefined;
  onCheckboxChange: (filterType: FilterBy, filterValue: string) => void;
}

const FilterOptions = ({
  options,
  optionLabels,
  filterType,
  activeFilters,
  onCheckboxChange
}: FilterOptionsProps) => {
  return (
    <div
      className={cn(
        'grid',
        'grid-cols-1',
        'absolute',
        'z-20',
        'bg-white',
        'shadow-lg',
        'border-silver-30',
        'border',
        'pr-6',
        'rounded-md',
        'w-max',
        'max-w-xs',
        'max-h-80',
        'overflow-y-auto'
      )}
    >
      {!!options.length ? (
        options.sort().map((option) => {
          return (
            <Checkbox
              key={option}
              label={optionLabels ? optionLabels[option] : option}
              name={`filter-${option}`}
              tooltip={
                filterType === 'Fleet' ? translateFleetName(option) : undefined
              }
              checked={activeFilters?.has(option) ?? false}
              onChange={() => onCheckboxChange(filterType, option)}
            />
          );
        })
      ) : (
        <Typography className="px-2">
          Currently, there are no options available.
        </Typography>
      )}
    </div>
  );
};

export default FilterOptions;
