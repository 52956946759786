export declare type RoleName = 'Admin' | 'RegistryUpdate' | 'SuperAdmin';

export interface Role {
  _id?: string;
  name: RoleName;
  createdBy: string;
  createdAt: number;
  updatedBy: string;
  updatedAt: number;
}

const findRole = (roleToFind: RoleName, roles: RoleName[]) => {
  return roles.find((role) => role === roleToFind);
};

export const isSuperAdmin = (roles: RoleName[]): boolean => {
  return !!findRole('SuperAdmin', roles);
};

export const isAdmin = (roles: RoleName[]): boolean => {
  return !!findRole('Admin', roles);
};

export const isRegistryUpdate = (roles: RoleName[]): boolean => {
  return !!findRole('RegistryUpdate', roles);
};
