import { Comment } from './Comment';
import { Team } from './Team';

export interface Vessel {
  Active: boolean;
  ActivityCode: number;
  AISLastUpdated?: number;
  AssetNumber: number;
  Beam?: number;
  CallSign?: string;
  Coast?: string;
  CourseOverGround?: number;
  CreatedBy: string;
  DateOfBuild?: number;
  Draught?: number;
  DWT?: number;
  ETA?: number;
  Flag?: string;
  Fleet: string;
  GrossTonnage?: number;
  Heading?: number;
  IHSETA?: number;
  IHSNextPort?: string;
  IMONumber?: number;
  LatestComment?: Comment;
  Latitude: number;
  LOA?: number;
  Longitude: number;
  MessageTimestamp?: number;
  MMSINumber?: number;
  NetTonnage?: number;
  NextPort?: string;
  OfficialNumber?: string;
  OnBerth?: boolean;
  Ownership: string;
  PK: string;
  PreviousPortADT?: number;
  PreviousPortCountry?: string;
  PreviousPortName?: string;
  ReceivedDate?: number;
  SK: string;
  SpeedOverGround?: number;
  Status: VesselStatus;
  StatusDetail?: string;
  TEU: number;
  UpdatedBy: string;
  VesselClass: string;
  VesselGroup: string;
  VesselName: string;
  VesselOperator?: string;
  VesselOwner?: string;
  VesselType: string;
  VoyageNumber?: number;
  Width?: number;
  CycloneFlagLastUpdatedUTC?: number;
  CycloneProximityFlag?: string;
  Team?: Team;
  UpdatedAt: number;
  UpdatedByUser?: boolean;
}

export enum VesselStatus {
  Moving = 'Moving',
  Stopped = 'Stopped',
  OutOfService = 'Out Of Service',
  NA = 'N/A'
}

export enum CycloneProximityFlag {
  Yes = 'Y',
  No = 'N'
}
