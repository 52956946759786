import {
  CollapsibleContainer,
  Divider
} from '@org-crowley/enterprise-react-component-library';
import { Fragment, useContext, useMemo } from 'react';
import {
  customCycloneLabel,
  groupTeamMemberNames,
  groupVesselsByAttr,
  groupVesselsByBooleanAttr,
  groupVesselsByAttrWithEnums
} from '../../utils/groupVesselsByAttr';
import { FilterBy, ToggleFilters } from '../../utils/useFilterState';
import { VesselContext } from '../../contexts/VesselContext';
import { CycloneProximityFlag } from '../../models/Vessel';
import Chip from '../Chip/Chip';
import FilterOptions from './FilterOptions';

interface Props {
  toggleFilters: ToggleFilters;
  onCheckboxChange: (filterType: FilterBy, filterValue: string) => void;
}

interface AvailableFilters {
  label: string;
  id: FilterBy;
  options: Record<string, number>;
  optionLabels?: Record<string, string>;
}

export const translateFleetName = (
  abbreviation: string
): string | undefined => {
  let fullFleetName;
  if (abbreviation === 'CPS') {
    fullFleetName = 'Crowley Petroleum Services';
  } else if (abbreviation === 'GSM') {
    fullFleetName = 'Global Ship Management';
  } else if (abbreviation === 'CMS') {
    fullFleetName = 'Crowley Marine Services';
  } else if (abbreviation === 'CFS') {
    fullFleetName = 'Crowley Fuel Services';
  } else {
    fullFleetName = undefined;
  }
  return fullFleetName;
};

export const FilterForm = ({ toggleFilters, onCheckboxChange }: Props) => {
  const { vessels } = useContext(VesselContext);

  const allToggleFilters: AvailableFilters[] = useMemo(() => {
    return [
      {
        label: 'Vessel Type',
        id: 'VesselType',
        options: groupVesselsByAttr(vessels, 'VesselType')
      },
      {
        label: 'Vessel Name',
        id: 'VesselName',
        options: groupVesselsByAttr(vessels, 'VesselName')
      },
      {
        label: 'Fleet',
        id: 'Fleet',
        options: groupVesselsByAttr(vessels, 'Fleet')
      },
      {
        label: 'Status',
        id: 'StatusDetail',
        options: groupVesselsByAttr(vessels, 'StatusDetail')
      },
      {
        label: 'Class',
        id: 'VesselClass',
        options: groupVesselsByAttr(vessels, 'VesselClass')
      },
      {
        label: 'Cyclone Proximity',
        id: 'CycloneProximityFlag',
        ...{
          ...groupVesselsByAttrWithEnums(
            CycloneProximityFlag,
            customCycloneLabel
          )
        },
        collapsed: true
      },
      {
        label: 'Crew/Team Member',
        id: 'Team',
        options: groupTeamMemberNames(vessels)
      },
      {
        label: 'Is active',
        id: 'Active',
        options: groupVesselsByBooleanAttr(vessels, 'Active', true),
        optionLabels: { true: 'Yes', false: 'No' }
      },
      {
        label: 'Coast',
        id: 'Coast',
        options: groupVesselsByAttr(vessels, 'Coast')
      }
    ];
  }, [vessels]);

  return (
    <div>
      {allToggleFilters.map(({ label, id, options, optionLabels }) => {
        const filterType = id as FilterBy;
        return (
          <Fragment key={label}>
            <div className="px-6 flex gap-x-2 flex-wrap">
              <CollapsibleContainer
                heading={label}
                startOpen={false}
                className="relative"
              >
                <FilterOptions
                  options={Object.keys(options)}
                  optionLabels={optionLabels}
                  filterType={filterType}
                  activeFilters={toggleFilters[filterType]}
                  onCheckboxChange={onCheckboxChange}
                />
              </CollapsibleContainer>
              {[...(toggleFilters[filterType] || [])].map((option: string) => (
                <Chip
                  onCloseClick={() => onCheckboxChange(filterType, option)}
                  label={optionLabels ? optionLabels[option] : option}
                  isActive
                  className="mb-2"
                  key={option}
                />
              ))}
            </div>
            <Divider className="mb-4 mt-0" />
          </Fragment>
        );
      })}
    </div>
  );
};
