import { Badge, Drawer } from '@org-crowley/enterprise-react-component-library';
import { MdFilterList } from 'react-icons/md';
import { FilterSidebar } from '../FilterSidebar/FilterSidebar';
import { useState, useContext } from 'react';
import { FilterContext } from '../../contexts/FilterContext';

const FilterSidebarButton = () => {
  const [filterSidebarIsOpen, setFilterSidebarIsOpen] = useState(false);
  const { activeToggleFilters } = useContext(FilterContext);

  const appliedToggleFiltersCount = Object.keys(activeToggleFilters).length;

  const onFilterSidebarClose = () => setFilterSidebarIsOpen(false);
  return (
    <>
      <div className="flex items-center">
        <>
          {appliedToggleFiltersCount > 0 ? (
            <Badge
              showBackgroundColor={false}
              variant="primary"
              className="sm:mr-2"
            >
              <span>{appliedToggleFiltersCount}</span>
            </Badge>
          ) : null}
          <MdFilterList
            size={20}
            className="block hover:cursor-pointer fill-blue-80"
            onClick={() => setFilterSidebarIsOpen(true)}
            aria-label="filter-sidebar-button"
            role="button"
          />
          <span className="hidden">Filter</span>
        </>
      </div>

      <Drawer isOpen={filterSidebarIsOpen} onClose={onFilterSidebarClose}>
        <FilterSidebar onFilterSidebarClose={onFilterSidebarClose} />
      </Drawer>
    </>
  );
};

export default FilterSidebarButton;
