import { TabNavigation } from '@org-crowley/enterprise-react-component-library';
import { useContext, useMemo } from 'react';
import {
  DETAILS_PAGE_URL,
  HOME_PAGE_URL,
  CARBON_INTENSITY_INDICATOR_URL,
  USER_MANAGEMENT_URL
} from '../../utils/routes';
import { useRouter } from 'next/router';
import { SelectedVesselsContext } from '../../contexts/SelectedVesselsContext';
import {
  TABS_LIST,
  tabLinks,
  NAVIGATION_TABS
} from '../../utils/topNavigationUtils';
import { FeatureFlagContext } from '../../contexts/FeatureFlagContext';
import FilterSidebarButton from '../FilterSidebarButton/FilterSidebarButton';
import { BarefleetItem } from '../../models/Barefleet';
import { Vessel } from '../../models/Vessel';
import { useOktaAuth } from '../../contexts/OktaContext';
import { isAdmin, isSuperAdmin } from '../../models/Role';

interface TopNavigationProps {
  currentBarefleetData?: BarefleetItem;
  selectedTabName?: string;
  vesselInfo?: Vessel;
}

const TopNavigation = ({
  currentBarefleetData,
  selectedTabName,
  vesselInfo
}: TopNavigationProps) => {
  const { selectedVessels } = useContext(SelectedVesselsContext);
  const { featureCiiEnabled } = useContext(FeatureFlagContext);
  const { userRoles } = useOktaAuth();

  const isSuperAdminRole = useMemo(() => isSuperAdmin(userRoles), [userRoles]);
  const isAdminRole = useMemo(() => isAdmin(userRoles), [userRoles]);

  const disabledTabs: NAVIGATION_TABS[] = useMemo(() => {
    const results: NAVIGATION_TABS[] = [];
    if (!featureCiiEnabled) {
      results.push(NAVIGATION_TABS.CII);
    }
    if (!isSuperAdminRole && !isAdminRole) {
      results.push(NAVIGATION_TABS.USER_MANAGEMENT);
    }
    return results;
  }, [featureCiiEnabled, isSuperAdminRole, isAdminRole]);

  const router = useRouter();

  const findActiveTab = useMemo(() => {
    const activeIndex = TABS_LIST(disabledTabs).findIndex((item) =>
      router.asPath.includes(tabLinks(item))
    );
    return activeIndex;
  }, [disabledTabs, router.asPath]);

  const isFilterVisible = useMemo(() => {
    const page = TABS_LIST(disabledTabs)[findActiveTab];
    const pageUrl = tabLinks(page);
    if (
      pageUrl === DETAILS_PAGE_URL ||
      pageUrl === CARBON_INTENSITY_INDICATOR_URL ||
      pageUrl === USER_MANAGEMENT_URL
    ) {
      return false;
    } else {
      return true;
    }
  }, [disabledTabs, findActiveTab]);

  const showOnMapHref = useMemo(() => {
    const currentDashboardTime = currentBarefleetData?.MeasurementTime;
    return selectedTabName === 'Dashboard' && currentDashboardTime
      ? `/?summary=${vesselInfo?.AssetNumber}&showRoute=true&time=${currentDashboardTime}`
      : `/?summary=${vesselInfo?.AssetNumber}`;
  }, [
    currentBarefleetData?.MeasurementTime,
    selectedTabName,
    vesselInfo?.AssetNumber
  ]);

  const changeUrl = (tabIndex: number) => {
    const page = TABS_LIST(disabledTabs)[tabIndex];
    const pageUrl = tabLinks(page);
    if (pageUrl === DETAILS_PAGE_URL) {
      router.push(`${pageUrl}/${selectedVessels[0].assetNumber}`, undefined, {
        shallow: true
      });
    } else if (pageUrl === HOME_PAGE_URL && currentBarefleetData) {
      router.push(showOnMapHref);
    } else if (
      pageUrl === HOME_PAGE_URL &&
      router.pathname == `${DETAILS_PAGE_URL}/[id]`
    ) {
      const activeVessel = selectedVessels.find(
        ({ assetNumber }) => assetNumber === vesselInfo?.AssetNumber
      );
      let url = pageUrl;
      if (!!activeVessel?.assetNumber) {
        url = `${pageUrl}?summary=${activeVessel?.assetNumber}`;
      }
      router.push(url, undefined, {
        shallow: true
      });
    } else {
      router.push(pageUrl, undefined, { shallow: true });
    }
  };

  return (
    <div className="flex -ml-2 -mt-3 md:ml-0 md:mt-0 md:h-16 items-center">
      <TabNavigation
        tabs={TABS_LIST(disabledTabs)}
        onChange={(tabIndex) => changeUrl(tabIndex)}
        activeTabIndex={findActiveTab}
        className="border-none w-max"
      />
      {isFilterVisible && (
        <div className="ml-3">
          <FilterSidebarButton />
        </div>
      )}
    </div>
  );
};

export default TopNavigation;
