import { Typography } from '@org-crowley/enterprise-react-component-library';
import cn from 'classnames';
import { MdClose } from 'react-icons/md';

interface ChipProps {
  label: string;
  isActive: boolean;
  onCloseClick: () => void;
  className?: string;
}

const Chip = ({ label, isActive, onCloseClick, className = '' }: ChipProps) => {
  return (
    <div
      className={cn(
        'rounded-full border flex w-fit h-fit px-4 py-2 items-center',
        { 'border-green-50 text text-green-50': isActive },
        { 'border-silver-30 text-silver-50': !isActive },
        className
      )}
    >
      <Typography variant="h100">{label}</Typography>
      <MdClose
        role="button"
        tabIndex={0}
        aria-label={`close-${label}`}
        onClick={onCloseClick}
        size={14}
        className={cn(
          'ml-2 cursor-pointer outline-none',
          { 'text-green-80': isActive },
          { 'text-silver-90': !isActive }
        )}
      />
    </div>
  );
};

export default Chip;
